import ButtonSubmitRound from "../../utilities/forms/buttonSubmitRound";


function CodeForm(props) {

  // Define variables
  const handleSubmit = props.handleSubmit;
  const code = props.code;
  const handleSetCode = props.handleSetCode;
  const buttonDisabled = props.buttonDisabled;
  const loading = props.loading;


  return (
    <>
      <form class='flex-column assess-code-form' onSubmit={(event) => handleSubmit(event)}>

        <div class='flex-column' style={{width: '100%', maxWidth: '300px'}}>
          <p class='assess-h6'>Enter your code</p>
          <input
            value={code}
            // placeholder={'Code'}
            onChange={event => handleSetCode(event.target.value)}
            class='input assess-input-code'
            minLength={5}
            maxLength={20}
          />
        </div>

        <div class='flex-column'>
          <ButtonSubmitRound
            value={"Continue"}
            disabled={buttonDisabled}
            loading={loading}
          />
          <p class='terms-text' style={{marginTop: '15px'}}>
            By clicking Continue, you agree to the <br /><a href='https://www.flowspeak.io/terms-and-conditions' target='_blank' rel="noreferrer">Terms & Conditions</a> and <a href='https://www.flowspeak.io/privacy-policy' target='_blank' rel="noreferrer">Privacy Policy</a>
          </p>
        </div>
      </form>
    </>
  );
}

export default CodeForm;