function CatalogLesson(props) {

  const lesson = props.lesson;

  return (
    <div class='flex-row catalog-lesson-container'>
      <span>{lesson.title}</span>
      <span>{lesson.duration}</span>
      <span>{lesson.languageLevel}</span>
      <span>{lesson.cefrLevel}</span>
      <span>{lesson.cefrSubLevel}</span>
      <span>{lesson.gseLevel}</span>
    </div>
  )
}

export default CatalogLesson;