function AsessScoreFeedback(props) {

  // Define variables
  const feedback = props.feedback;
  const title = props.title;


  return (
    <div>
      <p class='assess-score-p'>
        <strong>{title} feedback</strong>
      </p>
      <p class='assess-score-p'>{feedback}</p>
    </div>
  );
}

export default AsessScoreFeedback;