import apiCall from '../components/utilities/auth/apiCall';
import { assessmentSaveResponses } from '../components/utilities/auth/authApi';


const sendAssessmentResponses = async (payload) => {

  const code = payload.code;
  const responseType = payload.type;

  const formData = new FormData();
  formData.append('code', code);
  formData.append('response_type', responseType);

  // Loop the responses
  payload.responses.forEach((response, index) => {

    formData.append(`responses[][data]`, JSON.stringify(response));

    // Add the audio
    if( response.blob instanceof Blob ) {
      // Need a uniquie filename
      const fileName = `response-assesssment-${code}-${index}.wav`;
      formData.append(`responses[][audio]`, response.blob, fileName);
    }

  });


  return apiCall(assessmentSaveResponses, formData).then((response) => {
    return response;
  });
}

export default sendAssessmentResponses;