import axios from 'axios';
import { reportError } from "./sentry";


const scoreAssessment = async (payload) => {

  // Define variables
  let apiUrl = "https://microservice.flowspeak.io/api/v2/assessment";
  // if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  //   apiUrl = "http://localhost:8080/api/v2/assessment";
  // }
  const url = encodeURI(apiUrl);
  const apiKey = "fs000000000003044092320002333";

  const scoreType = payload.scoreType;
  const providedText = payload.providedText;
  const audioBlob = payload.audioBlob;
  const messages = payload.messages;
  const extras = payload.extras;


  // Build the form data
  const formData = new FormData();

  formData.append('scoreType', scoreType);
  formData.append('file', audioBlob, 'audio.wav');

  if( scoreType === 'read' ) {
    formData.append('providedText', providedText);
  }

  if( scoreType === 'conversation' ) {
    formData.append('messages', JSON.stringify(messages));
  }

  if( extras ) {
    formData.append('extras', JSON.stringify(extras));
  }

  // Include the last message if possible
  let lastMessage;
  if( messages ) {
    const lastMessageObject = messages[messages.length - 1];
    if( lastMessageObject && lastMessageObject.role === "assistant" ) {
      lastMessage = lastMessageObject.content;
      formData.append('lastMessage', lastMessage);
    }
  }


  const config = {
    method: 'post',
    url: url,
    headers: {
      authorization: `Bearer ${apiKey}`,
      "Content-Type": "multipart/form-data",
     },
    data : formData,
  };


  return axios(config).then(function (response) {
    let data;

    if( response.status === 200 ) {
      data = response.data;
    } else {
      console.log(' ');
      console.log('scoreAssessment::error', response);
      console.log(' ');
      data = {success: false, error: 'Sorry, there was an error'};
    }


    return data;
  }).catch(function (error) {
    reportError(error);
    return { success: false, error: error}
  });

}

export default scoreAssessment;