function ScoreSubLevelBlock(props) {

  // Define variables
  const inverseIndex = props.inverseIndex;
  const level = props.level;

  const extraWidth = inverseIndex * 9;
  const width = `calc(9% + ${extraWidth}%)`;

  let extraClass = '';
  if( level.includes('B')) { extraClass = 'is_b' }
  if( level.includes('C')) { extraClass = 'is_c' }


  return (
    <div class={`flex-column assess-sub-level ${extraClass}`}  style={{width: width}}>
      <div class='flex-column assess-sub-level-inner'>
        <p>{level}</p>
      </div>
    </div>
  );
}

export default ScoreSubLevelBlock;