function CefrSubBar(props) {

  // Define functions
  const title = props.title;
  const scoreObject = props.scoreObject;
  const label = scoreObject.label;
  const score = scoreObject.score;
  const width = `${score}%`;


  return (
    <div class='cefr-sub-bar-wrapper'>
      <h4>{title}</h4>

      <div class='flex-row cefr-sub-bar-container'>

        <div class='cefr-sub-bar-outer'>
          <div class='cefr-sub-bar-inner' style={{width: width}}></div>
        </div>
        <p>{label}</p>
      </div>
    </div>
  );
}

export default CefrSubBar;