function ProgressDots(props) {

  // Define variables
  const dots = props.dots || 3;
  const index = props.index;
  const width = dots * 18;


  return (
    <div class='flex-row progress-dots-container' style={{width: `${width}px`}}>
      {Array.from(Array(dots)).map((dot, dotIndex) =>
        <span
          key={`progress-dot-${dotIndex}`}
          class={index >= dotIndex ? 'selected' : null}
        >&nbsp;</span>
      )}
    </div>
  );
}

export default ProgressDots;