import React from "react";

import Nav from "../utilities/nav";
import HeaderMain from "../utilities/headermain";
import BottomNav from "../utilities/bottomnav";

import fadeInMainBody from "../../functions/fadeinmainbody";


class Suggestions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      submitError: [],
      submitted: false,
      successMessage: ''
    }

  }

  componentDidMount() {
    fadeInMainBody();
  }

  render() {

    let submitButton;
    if( this.state.submitted ){
      submitButton = <input type="submit" class='button' disabled value="Send" />;
    }else{
      submitButton = <input type="submit" class='button' value="Send" />;
    }

    let formFields = (
      <div>
        <textarea class='product-feedback-message'></textarea>

        <div class="field">
          {submitButton}
        </div>
      </div>
    )

    if( this.state.successMessage ) {
      formFields = (
        <p class='succcess-message mt-6'>{this.state.successMessage}</p>
      )
    }

    let errorMessage;

    if( this.state.submitError.length > 0 ) {
      errorMessage = (
        <div class='form-error-header form-error'>
          {this.state.submitError} &nbsp;
        </div>
      )
    }

    return (
      <div class='wrapper gray-background'>
        <Nav />
        <div class='main-body invisible'>
          <HeaderMain title="Help" />
          <div class='main-body-content'>

              <div class='product-feedback-wrapper' style={{marginTop: '-30px'}}>
                <p>Need help or have a suggestion? We're here for you.</p>

                <br />

                <h3>Whatsapp</h3>
                <p>Chat with us on Whatsapp: +1 888 212-6008 or scan the QR code below to open Whatsapp.</p>
                <img
                  src='https://files.flowspeak.io/files/whatsapp-qrcode.jpg'
                  style={{border: '1px solid #DCDCDC', width: '180px', height: '180px'}}
                />

                <br /><br />

                <h3>Email</h3>
                <p>Email us at <a href='mailto:support@flowspeak.io'>support@flowspeak.io</a></p>

              </div>


          </div>
        </div>

        <BottomNav />

      </div>
    );
  }
}

export default Suggestions;