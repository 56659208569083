import React from 'react';
import { Link } from 'react-router-dom';

import '../../../styles/lessonCard.css';
import VectorIcon from '../vectorIcon';
import ScoreDonut from '../learning_module/scoredonut';
import scoreWord from '../../../functions/scoreWord';

const defaultImageUrl = 'https://images.pexels.com/photos/7130552/pexels-photo-7130552.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2';


function LessonCardDetail(props) {

  // Define variables
  const lesson = props.lesson;
  const exerciseId = lesson.exerciseId;
  const lessonType = lesson.lessonType;
  const level = lesson.level;
  const thumbnailUrl = lesson.thumbnailUrl || defaultImageUrl;
  const score = lesson.score;
  const scoreIelts = lesson.scoreIelts;
  const isIelts = lesson.isIelts;
  const placeholderLesson = lesson.placeholderLesson;


  // Screen components
  let displayLessonType = lessonType;
  if( displayLessonType ) {
    displayLessonType = displayLessonType.replace('ListeningLesson', 'Listening');
    displayLessonType = displayLessonType.replace('AiTalk', 'AI Talk');
  }

  // let displayScore = `${score}%`;
  let scorePercent = score;
  let word = scoreWord(scorePercent);

  if( isIelts && scoreIelts ) {
    scorePercent = scoreIelts;
    word = scorePercent;
  }


  let footerElement;
  if( !placeholderLesson ) {

    let scoreElement = <span style={{width: 30, height: 30}}>&nbsp;</span>;

    if( scorePercent > 0 ) {
      scoreElement = (
        <>
          {word}
          <ScoreDonut
            scorePercent={scorePercent}
            isIelts={isIelts}
          />
        </>
      );
    }

    footerElement = (
      <div class='flex-row lesson-card-content-footer'>

        <p class='small flex-row'>
          {scoreElement}
        </p>

        <Link
          to={`/progress/lessons/${exerciseId}`}
          class='flex-row'
          style={{alignItems: 'center'}}
        >
          View Details
          <VectorIcon icon={'chevronForwardOutline'} size={'1rem'} />
        </Link>

      </div>
    );
  }


  return (
    <div class='lesson-card-wrapper'>

      <div class='lesson-card-details-container'>

        <div
          class='lesson-card-image'
          style={{backgroundImage: `url(${thumbnailUrl})`}}
        ></div>

        <div class='lesson-card-content'>

          <div
            class='flex-row'
            style={{justifyContent: 'space-between'}}
          >
            <p class='small light-gray'>{displayLessonType}</p>
            <p class='small light-gray'>
              <i>{lesson.completedOn}</i>
            </p>
          </div>

          <h6>{lesson.title}</h6>
          <p class='small'>
            <span class='card-detail-level'><i>{level}{lesson.duration ? ',' : null}</i></span>
            &nbsp;
            <span class='card-detail-duration'><i>{lesson.duration}</i></span>
          </p>

          {footerElement}

        </div>

      </div>

    </div>
  );
}

export default LessonCardDetail;
