import { useState, useEffect } from "react";


function RecordTimer(props) {

  // Define variables
  const [counter, setCounter] = useState(props.seconds);
  const handleTimeout = props.handleTimeout;
  const recordStatus = props.recordStatus;


  // Component load actions
  useEffect(() => {

    if( counter === 0 ) {
      handleTimeout();
    }

    // const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);

    const timer = setInterval(() => {
      if( recordStatus !== 'processing' ) {
        if (counter > 0) {
          setCounter(counter - 1);
        }
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [counter, recordStatus]);


  return (
    <div class='flex-column assess-timer-container'>
      <p>Timer</p>
      <p>{counter}</p>
      <p>seconds</p>
    </div>
  );
}

export default RecordTimer;