function checkBrowser() {
  let browser;
  const userAgent = navigator.userAgent.toLowerCase();

  if( userAgent.match(/chrome|chromium|crios/i) ) {
    browser = 'chrome';
  } else if( userAgent.match(/firefox|fxios/i) ) {
    browser = 'firefox';
  } else if( userAgent.match(/safari/i) ) {
    browser = 'safari';
  } else if( userAgent.match(/opr\//i) ) {
    browser = 'Opera';
  } else if( userAgent.match(/edg/i) ) {
    browser = 'Edge';
  }

  return browser;
}

export default checkBrowser;