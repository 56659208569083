import { useState, useEffect } from "react";

import apiCall from "../utilities/auth/apiCall";
import { lessonCatalog } from "../utilities/auth/authApi";
import CatalogSection from "./catalogSection";



function CatalogList() {

  // Define variables
  const [bitesList, setBitesList] = useState([]);
  const [listeningList, setListeningList] = useState([]);
  const [expressionList, setExpressionList] = useState([]);
  const [boosterList, setBoosterList] = useState([]);
  const [speakingList, setSpeakingList] = useState([]);
  const [aitalkList, setAitalkList] = useState([]);


  // Component load actions
  useEffect(() => {
    apiCall(lessonCatalog).then((response) => {
      if( response.success ) {

        const data = response.data;

        setBitesList(data.bites);
        setListeningList(data.listening);
        setExpressionList(data.expressions);
        setBoosterList(data.boosters);
        setSpeakingList(data.speaking);
        setAitalkList(data.aitalks);

      } else {
        reportError(response.error);
      }
    }).catch((error) => {
      reportError(error);
    });
  }, []);


  return (
    <div>
      <div class='catalog-dynamic-learning'>
        <h2>Dynamic Learning</h2>

        <h4 style={{marginTop: '30px'}}>6 Lesson Types</h4>
        <div class='flex-row catalog-lesson-types-container'>

          <div class='catalog-lesson-type-container'>
            <div class='catalog-lesson-type-header'>Bite</div>
            <p class='catalog-lesson-type-body'>
              Bite-sized lesson to learn how to use English in real-world scenarios.
              <br />
              Receive pronunciation, speed, and fluency feedback.
            </p>
          </div>

          <div class='catalog-lesson-type-container'>
            <div class='catalog-lesson-type-header'>Listening</div>
            <p class='catalog-lesson-type-body'>
              Tune your ear by listening to native speakers in real-world situations.
              <br />
              Test comprehension of the scenario.
            </p>
          </div>

          <div class='catalog-lesson-type-container'>
            <div class='catalog-lesson-type-header'>Expression</div>
            <p class='catalog-lesson-type-body'>
              Master everyday phrases that are frequently used in conversations.
              <br />
              Receive detailed pronunciation feedback.
            </p>
          </div>

        </div>

        <div class='flex-row catalog-lesson-types-container'>

          <div class='catalog-lesson-type-container'>
            <div class='catalog-lesson-type-header'>Booster</div>
            <p class='catalog-lesson-type-body'>
              Learn useful phrases for everyday situations and then practice using those phrases in an unscripted conversation.
              <br />
              Receive feedback on fluency & relevance.
            </p>
          </div>

          <div class='catalog-lesson-type-container'>
            <div class='catalog-lesson-type-header'>Scripted Conversations</div>
            <p class='catalog-lesson-type-body'>
              Practice the back and forth of a real conversation scenario.
              <br />
              Feedback on pronunciation, speed, and fluency.
            </p>
          </div>

          <div class='catalog-lesson-type-container'>
            <div class='catalog-lesson-type-header'>AI Talk</div>
            <p class='catalog-lesson-type-body'>
              Topic based free-flowing conversations with AI ( AI responds to what you say ).
              <br />
              Receive feedback on fluency & relevance.
            </p>
          </div>

        </div>
      </div>

      <div class='catalog-jump-to-section'>
        <h2>Jump to Section</h2>
        <a href='#Bites'>Bites</a>
        <a href='#Listening'>Listening</a>
        <a href='#Expressions'>Expressions</a>
        <a href='#Boosters'>Boosters</a>
        <a href='#Scripted Conversations'>Scripted Conversations</a>
        <a href='#AI Talks'>AI Talks</a>
      </div>


      <CatalogSection title={'Bites'} list={bitesList} />
      <CatalogSection title={'Listening'} list={listeningList} />
      <CatalogSection title={'Expressions'} list={expressionList} />
      <CatalogSection title={'Boosters'} list={boosterList} />
      <CatalogSection title={'Scripted Conversations'} list={speakingList} />
      <CatalogSection title={'AI Talks'} list={aitalkList} />

    </div>
  )
}

export default CatalogList;