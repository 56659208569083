import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

import apiCall from '../../utilities/auth/apiCall';
import { getOrganizationBySlug } from '../../utilities/auth/authApi';


function StartHeader(props) {

  // Define variables
  const { slug } = useParams();
  const [orgLogo, setOrgLogo] = useState(null);
  const defaultOrgLogo = props.defaultOrgLogo;
  const big = props.big;
  let useRightMargin = false;


  // Component load actions
  useEffect(() => {
    if( defaultOrgLogo ) {
      setOrgLogo(defaultOrgLogo);
    } else if( slug ) {
      loadOrgData();
    }
  }, []);


  // Define functions
  function loadOrgData() {
    apiCall(getOrganizationBySlug, {slug: slug}).then((response) => {
      if( response.success ) {
        setOrgLogo(response.data.logo_url);
      }
    });
  }


  // Screen components
  let orgLogoElement;
  if( orgLogo ) {
    useRightMargin = true;

    orgLogoElement = (
      <div
        class='org-logo-image'
        style={{
          backgroundImage: "url(" + orgLogo + ")",
        }}>
      </div>
    );
  }


  return (
    <div style={{width: '100%'}}>
      <div class={`flex-row assess-start-logos ${big ? 'big' : ''}`}>
        <div class={`flow-logo-lockup ${useRightMargin ? 'right-margin' : ''}`}></div>
        {orgLogoElement}
      </div>

      <div class={`flex-column assess-start-words ${big ? 'big' : ''}`}>
        <p class='assess-sub-heading'>English Speaking</p>
        <p class='assess-heading'>Assessment</p>
      </div>
    </div>
  );
}

export default StartHeader;