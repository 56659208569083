import React from 'react';


function MicrophoneAccessContent (props) {

  const reloadAction = props.reloadAction ? <li><a href={window.location.href} class='help-reload'>Reload this page</a></li> : '';

  function handleClick(event) {
    const target = event.target;
    const dataOl = target.getAttribute('data-ol');
    const ol = document.getElementById(dataOl);
    if( ol ) {
      ol.classList.toggle('is-hidden');
    }
  }


  return (
    <div style={{width: '100%'}}>
      <div class='mic-access-instruction'>
        <h5 data-ol='ol-android-chrome' onClick={handleClick}>Android / Chrome</h5>
        <ol class='is-hidden' id='ol-android-chrome'>
          <li>On your Android device, open the Chrome app.</li>
          <li>To the right of the address bar, tap the three dots and then Settings.</li>
          <li>Tap Site Settings.</li>
          <li>Tap Microphone or Camera.</li>
          <li>Tap to turn the microphone on.</li>
          {reloadAction}
        </ol>
      </div>

      <div class='mic-access-instruction'>
        <h5 data-ol='ol-ios-safari' onClick={handleClick}>IOS / Safari</h5>
        <ol class='is-hidden' id='ol-ios-safari'>
          <li>On your IOS device, open the phone Settings.</li>
          <li>Scroll down to the Safari option and tap it.</li>
          <li>Scroll to the 'Settings For Websites' section and tap the 'Microphone' option.</li>
          <li>Choose 'Ask' or 'Allow'</li>
          {reloadAction}
        </ol>
      </div>

      <div class='mic-access-instruction'>
        <h5 data-ol='ol-ios-chrome' onClick={handleClick}>IOS / Chrome</h5>
        <ol class='is-hidden' id='ol-ios-chrome'>
          <li>On your IOS device, open the phone Settings.</li>
          <li>Scroll down to the Chrome option and tap it.</li>
          <li>Tap the 'Microphone' option to enable the microphone.</li>
          {reloadAction}
        </ol>
      </div>

      <div class='mic-access-instruction'>
        <h5 data-ol='ol-desktop-chrome' onClick={handleClick}>Desktop / Chrome</h5>
        <ol class='is-hidden' id='ol-desktop-chrome'>
          <li>In the URL bar, tap the microphone, video, or settings icon.</li>
          <li>Choose the opion that says: "Continue allowing https://app.flowspeak.io to access your microphone".</li>
          <li>Press 'Done'.</li>
          {reloadAction}
        </ol>
      </div>

      <div class='mic-access-instruction'>
        <h5 data-ol='ol-desktop-safari' onClick={handleClick}>Desktop / Safari</h5>
        <ol class='is-hidden' id='ol-desktop-safari'>
          <li>Open the Safari 'Preferences'.</li>
          <li>Tap on the 'Websites' icon.</li>
          <li>Tap on the 'Microphone' option.</li>
          <li>Find 'app.flowspeak.io' in the list of websites.</li>
          <li>Choose 'Allow' from the access dropdown.</li>
          {reloadAction}
        </ol>
      </div>

      {/* <div class='mic-access-instruction'>
        <h5>Desktop / Firefox</h5>
        <ol>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ol>
      </div> */}

    </div>
  );
}

export default MicrophoneAccessContent;