import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { reportError } from "../../functions/sentry";
import toast from 'react-hot-toast';
import * as api from "../utilities/auth/authApi";
import { saveToken } from "../utilities/auth/tokenHelpers";


function SignupJsaf() {

  // Define variables
  const navigate = useNavigate();
  let initialUtmCode;
  let initialReferrer;

  if( URLSearchParams ) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    initialUtmCode = urlParams.get('utm_code');
    initialReferrer = document.referrer;
  }

  const [submitted, setSubmitted] = useState(false);
  const [signupError, setsignupError] = useState([]);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [utmCode] = useState(initialUtmCode);
  const [referrer] = useState(initialReferrer);
  const [promoCode, setPromoCode] = useState('JSAFtry7');
  const [password, setPassword] = useState('');
  // const [passwordConfirm, setPasswordConfirm] = useState('');
  const [joinNewsletter, setJoinNewsletter] = useState('');
  const [agreeToTerms, setagreeToTerms] = useState('');
  const [organization, setOrganization] = useState('');
  // const [unMatchPwdErr, setunMatchPwdErr] = useState('');


  // Define functions
  function redirectToLogin() {
    navigate('/start#signin');
  }

  function handleFormSubmit(event) {
    event.preventDefault();

    // Disable the submit button
    setSubmitted(true);

    if (firstName && email && password && agreeToTerms) {

      // Get the local timezone offset
      const timezoneOffset = new Date().getTimezoneOffset();

      const payload = {
        "user": {
          first_name: firstName,
          last_name: lastName,
          email: email,
          password: password,
          utm_code: utmCode,
          referrer: referrer,
          promo_code: promoCode,
          organization: organization,
          join_newsletter: joinNewsletter,
          agree_to_terms: agreeToTerms,
          timezone_offset: timezoneOffset
        }
      };

      api.signup(payload).then((response) => {

        if( response && response.data ){
          if( response.data.success ) {

            saveToken(response.data);

            // Send to the welcome page
            // navigate('/welcome');
            navigate('/welcome-choose-pathway');

          } else {
            setsignupError(response.data.errors);
            toast.error(response.data.errors.join('. '));

            // Enable the submit button
            setSubmitted(false);
          }
        } else {
          alert('Sorry, there was an error. Please contact support@flowspeak.io for assistance.')

          // Enable the submit button
          setSubmitted(false);
        }

      }).catch((error) => {

        setsignupError(error);
        toast.error(error);

        // Enable the submit button
        setSubmitted(false);

        // Log the error
        reportError(error);
      });

    } else {
      if( !agreeToTerms ) {
        alert('Please check the box to agree to the Terms & Conditions and Privacy Policy');
      }

      // Enable the submit button
      setSubmitted(false);
    }
  };


  // Screen components
  let submitButton = <input type="submit" class='button is-medium' value="アカウント登録" />;
  if( submitted ){
    submitButton = <input type="submit" class='button is-medium' disabled value="アカウント登録" />;
  }



  return (
    <section>
      <form onSubmit={handleFormSubmit}>

        {/* <h4>{this.state.title}</h4> */}

        <div class='form-error-header form-error'>
          {signupError}
        </div>

        <div class="field">
          <label class="label">姓</label>
          <div class="control">
            <input
              name="last_name"
              type="text"
              value={lastName}
              onChange={event => setLastName(event.target.value)}
              required
              placeholder='Your last name'
              class='input'
              autoFocus
            />
          </div>
        </div>

        <div class="field">
          <label class="label">名</label>
          <div class="control">
            <input
              name="first_name"
              type="text"
              value={firstName}
              onChange={event => setFirstName(event.target.value)}
              required
              placeholder='Your first name'
              class='input'
              autoFocus
            />
          </div>
        </div>

        <div class="field">
          <label class="label">所属先（学校・大学・会社）</label>
          <div class="control">
            <input
              name="organization"
              type="text"
              value={organization}
              onChange={event => setOrganization(event.target.value)}
              placeholder='Your organization'
              class='input'
              autoFocus
            />
          </div>
        </div>

        <div class="field">
          <label class="label">メールアドレス</label>
          <div class="control">
            <input
              name="email"
              type="email"
              value={email}
              onChange={event => setEmail(event.target.value)}
              required
              placeholder='Email address'
              class='input'
              maxLength={50}
            />
          </div>
        </div>

        <div class="field">
          <label class="label">パスワード</label>
          <div class="control">
            <input
              name="password"
              type="password"
              value={password}
              onChange={event => setPassword(event.target.value)}
              minLength="8"
              title="Password must be at least 8 characters"
              required
              placeholder='Password'
              class='input'
            />
          </div>
        </div>

        <div class='field is-hidden'>
          <label class="label">Promo Code</label>
          <div class="control">
            <input
              name="promo_code"
              type="text"
              value={promoCode}
              onChange={event => setPromoCode(event.target.value)}
              minLength="5"
              placeholder='Enter your code'
              class='input'
            />
          </div>
        </div>

        <div class="field mt-5">
          <label class="checkbox">
            <input
              type="checkbox"
              name='join_newsletter'
              onChange={event => setJoinNewsletter(event.target.checked)}
            />
            <p>ニュースレター登録</p>
          </label>
        </div>

        <div class="field mt-4">
          <label class="checkbox">
            <input
              type="checkbox"
              name='agree_to_terms'
              onChange={event => setagreeToTerms(event.target.checked)}
            />
            <p>
              利用規約を同意する（英文）<a href='https://www.flowspeak.io/terms-and-conditions' target='_blank' rel="noreferrer">Terms & Conditions</a> and <a href='https://www.flowspeak.io/privacy-policy' target='_blank' rel="noreferrer">Privacy Policy</a>
            </p>
          </label>
        </div>

        <input name="utm_code" type="hidden" value={utmCode} />
        <input name="referrer" type="hidden" value={referrer} />

        <div class="field">
          {submitButton}
        </div>

      </form>

      <div class='login-secondary-action'>
        すでにアカウントがある方はこちら &nbsp;
        <a href='#signin' class='' title="Sign Up" onClick={redirectToLogin}>ログイン</a>
      </div>
    </section>
  );
}

export default SignupJsaf;