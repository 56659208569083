import React from "react";
import { Link } from "react-router-dom"


function CourseCard(props) {

  const course = props.course;
  const status = course.status;
  const premium = course.premium;
  // const hasPremiumSubscription = props.hasPremiumSubscription;
  const learnerHasAccess = course.learnerHasAccess;
  let action;
  let cost;


  // if( !premium || ( premium && hasPremiumSubscription )) {
  if( !premium || ( premium && learnerHasAccess )) {

    if( status === 'open' ) {
      action = (
        <div class='action-button-wrapper'>
          <button class='button action-button' title='Tap to start course' onClick={() => props.handleStartCourse(course.id)}>Start Course</button>
        </div>
      );
    } else if( status === 'active' ) {
      action = (
        <div class='action-wrapper in-progress'>
          <p class='active-label'>Course in progress</p>
          <button class='course-cancel' title='Tap to cancel course' onClick={() => props.handleCancelCourse(course.id)}>Cancel Course</button>
        </div>
      );
    } else if( status === 'complete' ) {

      action = (
        <div class='course-completed-retake-wrapper'>
          <p>
            <span class='icon icon-complete-purple'></span>
            &nbsp;
            Completed
          </p>
          <button class='' title='Tap to retake course' onClick={() => props.handleStartCourse(course.id)}>Retake Course</button>
        </div>
      );

    } else {
      // TO DO - handle
    }

  } else {
    action = <Link to='/packages' class='course-upgrade' title='View premium membership'>Upgrade to Premium to take this course</Link>;
  }


  // If the learner has a premium subscription, dont show the cost element
  // if( !hasPremiumSubscription ) {
  if( !learnerHasAccess ) {
    if( premium ) {
      cost = (
        <div class='premium-label'>
          <div class='icon icon-star'></div>
          Premium
        </div>
      );

    } else {
      cost = <p class='course-free-label'>Free</p>;
    }
  }


  return (

    <div class='lesson-card course'>

      <div class='course-card-header'>
        <h3>{course.title}</h3>
        {cost}
      </div>

      <div class='course-card-details'>
        <div><span>Level:</span> {course.level}</div>
        <div><span>Duration:</span> {course.duration}</div>
        <div><span>Lessons:</span> {course.lessonCount}</div>
      </div>

      <p class='course-description'>{course.description}</p>

      {action}

    </div>

  );
}

export default CourseCard;