import RenderHtmlText from "../../utilities/renderhtmltext";
import StartHeader from "./startHeader";
import ProgressDots from "../../utilities/progressDots";
import ButtonSubmitRound from "../../utilities/forms/buttonSubmitRound";


function AssessmentInstructions(props) {

  // Define variables
  const instructions = props.instructions;
  const logProgress = props.logProgress;
  const organizationLogo = props.organizationLogo;


  // Screen components
  let retryElement = instructions.retry ? <RenderHtmlText string={instructions.retry} /> : null;
  let translationRetryElement = instructions.retryTranslation ? <RenderHtmlText string={instructions.retryTranslation} /> : null;
  let translationElement;

  if( instructions.translation ) {
    translationElement = (
      <div class='assess-translate-container'>
        <RenderHtmlText string={instructions.translation} />
        {translationRetryElement}
      </div>
    );
  }


  return (
    <>
      <StartHeader defaultOrgLogo={organizationLogo} />
      <div class='flex-column assess-glass-extra'>

        <div class='flex-column' style={{width: '100%'}}>
          <h2>How it Works</h2>

          <div class='assess-instructions-container'>
            <RenderHtmlText string={instructions.body} />
            {retryElement}
          </div>

          {translationElement}
        </div>

        <div class='flex-column'>
          <ButtonSubmitRound
            value={"Continue"}
            handleButtonClick={() => logProgress({step: 3})}
          />

          <div class='assess-dots-container'>
            <ProgressDots index={1} dots={5} />
          </div>
        </div>
      </div>
    </>
  );
}

export default AssessmentInstructions;