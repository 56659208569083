import { useState } from "react";

import { reportError } from "../../../functions/sentry";
import apiCall from "../../utilities/auth/apiCall";
import { assessmentUpdate } from "../../utilities/auth/authApi";
import { languageCodes } from "../../utilities/languagecodes";

import alertMessage from "../../utilities/alertMessage";
import ButtonSubmitRound from "../../utilities/forms/buttonSubmitRound";
import StartHeader from "./startHeader";
import ProgressDots from "../../utilities/progressDots";

let dummyData = {firstName: '', lastName: '', email: '', nativeLanguage: ''};
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  dummyData = {firstName: 'John', lastName: 'Good', email: 'a@b.com'};
}


function AssessmentRegister(props) {

  // Define variables
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [firstName, setFirstName] = useState(dummyData.firstName);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [nativeLanguage, setNativeLanguage] = useState(null);
  const config = props.config;
  const organizationLogo = config.organizationLogo;
  const logProgress = props.logProgress;
  const setInstructions = props.setInstructions;


  // Define functions
  function handleSubmit(event) {
    event.preventDefault();
    setButtonDisabled(true);

    if( (config.enableFirstName ? firstName.length >= 2 : true) && (config.enableLastName ? lastName.length >= 2 : true) && (config.enableEmail ? email.length >= 5 : true) && (config.enableNativeLanguage ? nativeLanguage.length >= 2 : true) ) {

      const payload = {
        code: config.code,
        firstName: firstName,
        lastName: lastName,
        email: email,
        nativeLanguage: nativeLanguage,
        section: 1,
        step: 1
      }

      apiCall(assessmentUpdate, payload).then((response) => {
        if( response.success ) {

          if( response.data?.instructions ) {
            setInstructions(response.data.instructions);
          }

          logProgress({step: 2});
        } else {
          alertMessage(response.error, 'error');
          reportError(response.error)
        }
      });

    } else {
      alertMessage('Please fill out all of the form fields.', 'error');

      setTimeout(() => {
        setButtonDisabled(false);
      }, 2000);
    }
  }


  // Screen components
  let firstNameElement;
  let lastNameElement;
  let emailElement;
  let nativeLanguageElement;

  if( config.enableFirstName ) {
    firstNameElement = <input value={firstName} onChange={event => setFirstName(event.target.value)} class='input' placeholder="Your first name" minLength={2} required />;
  }

  if( config.enableLastName ) {
    lastNameElement = <input value={lastName} onChange={event => setLastName(event.target.value)} class='input' placeholder="Your last name" minLength={2} required />;
  }

  if( config.enableEmail ) {
    emailElement = <input value={email} onChange={event => setEmail(event.target.value)} class='input' placeholder="Your email" type='email' minLength={5} required />;
  }

  if( config.enableNativeLanguage ) {
    // nativeLanguageElement = <input value={nativeLanguage} onChange={event => setNativeLanguage(event.target.value)} class='input' placeholder="Your native language" minLength={2} required />;
    nativeLanguageElement = (
      <select
        type='select'
        class='select'
        value={nativeLanguage}
        onChange={(event) => setNativeLanguage(event.target.value)}
        minLength={2}
        required
      >
        <option value="" disabled selected>Select your native language</option>
        {languageCodes.map((language, index) =>
          <option key={`assess-lang-code-${index}`} value={language.value}>{language.name}</option>
        )}
      </select>
    );
  }


  return (
    <>
      <StartHeader defaultOrgLogo={organizationLogo} />
      <form class='flex-column assess-glass-extra' onSubmit={(event) => handleSubmit(event)}>

        <div class='flex-column' style={{width: '100%'}}>
          <h2>Register</h2>

          {firstNameElement}
          {lastNameElement}
          {emailElement}
          {nativeLanguageElement}
        </div>

        <div class='flex-column'>

          <p class='assess-agree'>
            By pressing Continue, you acknowledge that your responses and recorded audio will be shared with members of your organization.
          </p>

          <ButtonSubmitRound
            value={"Continue"}
            disabled={buttonDisabled}
            loading={buttonDisabled}
          />

          <div class='assess-dots-container'>
            <ProgressDots index={0} dots={5} />
          </div>
        </div>
      </form>
    </>
  );
}

export default AssessmentRegister;