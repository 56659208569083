import RenderHtmlText from "../../utilities/renderhtmltext";
import ButtonSubmitRound from "../../utilities/forms/buttonSubmitRound";
import ProgressDots from "../../utilities/progressDots";
import StartHeader from "./startHeader";


function AssessmentReady(props) {

  // Define variables
  const logProgress = props.logProgress;
  const instructions = props.instructions;
  const organizationLogo = props.organizationLogo;


  // Screen components
  let translationElement;
  if( instructions.translation ) {
    translationElement = (
      <div class='assess-translate-container'>
        <RenderHtmlText string={instructions.translation} />
      </div>
    );
  }


  return (
    <>
      <StartHeader defaultOrgLogo={organizationLogo} />
      <div class='flex-column assess-glass-extra'>

        <div class='flex-column' style={{width: '100%'}}>
          <h2>You are ready!</h2>

          <div class='assess-instructions-container'>
            <RenderHtmlText string={instructions.body} />
          </div>

          {translationElement}
        </div>

        <div class='flex-column'>
          <ButtonSubmitRound
            value={"Continue"}
            handleButtonClick={() => logProgress({section: 2, step: 0})}
          />

          <div class='assess-dots-container'>
            <ProgressDots index={4} dots={5} />
          </div>
        </div>
      </div>
    </>
  );
}

export default AssessmentReady;