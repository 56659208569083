import { useState } from "react";
import ScoreSubLevel from "./scoreSubLevel";
import CefrSubBar from "../helpers/cefrSubBar";
import ScoreDetailCard from "./scoreDetailCard";
import ScoreReportEmail from "./scoreEmailReport";


function ScoreResults(props) {

  // Define variables
  const [showResponseIndex, setShowResponseIndex] = useState(0);
  const code = props.code;
  const score = props.score;
  const cefrLevel = score.cefrLevel;
  const scoresByType = score.scoresByType;
  const responses = score.responses;
  const testTakerDetails = score.testTakerDetails;

  const overall = scoresByType.overall;
  const pronunciation = scoresByType.pronunciation;
  const grammar = scoresByType.grammar;
  const vocabulary = scoresByType.vocabulary;
  const fluency = scoresByType.fluency;
  const relevance = scoresByType.relevance;


  // Define functions
  function expandResponse(index, cardRef) {
    setShowResponseIndex(index);

    setTimeout(() => {
      if( cardRef && cardRef.current ) {
        cardRef.current.scrollIntoView({ block: 'start', behavior: "smooth" });
      }
    }, 100);
  }


  return (
    <div style={{width: '100%'}}>

      <div class='flex-column assess-score-section'>
        <p class='assess-fancy'>Your English speaking level is</p>
        <p class='assess-cefr-level'>{cefrLevel}</p>
        <p class='assess-cefr-level-detail'>{overall.score} / 100</p>
      </div>

      <ScoreSubLevel overall={overall} />

      <div class='flex-column assess-score-section'>
        <p class='assess-fancy'>Score breakdown</p>
        <br />

        <CefrSubBar scoreObject={pronunciation} title={'Pronunciation'} />
        <CefrSubBar scoreObject={grammar} title={'Grammar'} />
        <CefrSubBar scoreObject={vocabulary} title={'Vocabulary'} />
        <CefrSubBar scoreObject={fluency} title={'Fluency'} />
        <CefrSubBar scoreObject={relevance} title={'Relevance'} />

        <a
          href='https://www.flowspeak.io/articles/pathways-level-up-your-speaking'
          rel="noreferrer"
          target='_blank'
          style={{marginTop: '30px', color: '#6D6D6D', textDecoration: 'underline'}}
        >Learn more about CEFR scores</a>
      </div>


      <div class='flex-column assess-score-section'>
        <p class='assess-fancy'>Response Details</p>
        <br />

        {responses.map((response, index) =>
          <ScoreDetailCard
            key={`assess-response-${index}`}
            response={response}
            index={index}
            expandResponse={expandResponse}
            showResponse={showResponseIndex === index}
          />
        )}
      </div>

      <div class='flex-column assess-score-section'>
        <p class='assess-fancy'>Your details</p>

        <div class='flex-column assess-test-taker-details'>
          <p class='assess-test-taker-name'>{testTakerDetails.firstName} {testTakerDetails.lastName}</p>
          <p>{testTakerDetails.email}</p>
          <p>Code: {testTakerDetails.assessmentIdentifer}</p>
        </div>

        <ScoreReportEmail code={code} />
      </div>

    </div>
  );
}

export default ScoreResults;