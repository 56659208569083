function CodeAskMic(props) {

  // Define variables
  const askForMicPermission = props.askForMicPermission;


  return (
    <>
      <div class='flex-column'>
        <br />
        <p class='assess-h6'>Before we begin</p>
        <p class='has-text-centered'>We need access to your microphone so we can record your voice.</p>
        <br />
        <p class='has-text-centered'>Click the button below to grant access. When the popup appears, click "Allow on every visit".</p>
        <br />
        <br />
        <button
          type='button'
          class='button'
          onClick={() => askForMicPermission()}
        >Grant Microphone Access</button>
      </div>
    </>
  );
}

export default CodeAskMic;