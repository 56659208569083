

import axios from 'axios';
import { reportError } from "./sentry";


const scorePronunciation = async (audioBlob, audioFileUri, providedText) => {

  const host = window.location.host;
  // const onStaging = host.search('--flow-web.netlify.app') > 0;
  // if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development' || onStaging) {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {

    // Change this to toggle real testing
    const testing = true;
    // const testing = false;

    if( testing ) {
      const dummyData = generateDummyData(audioFileUri, providedText);
      return dummyData;
    }
  }

  // const apiUrl = "http://localhost:8080/api/pronunciation";
  const apiUrl = "https://microservice.flowspeak.io/api/pronunciation";
  const url = encodeURI(apiUrl);

  const formData = new FormData();
  formData.append('apiKey', "fs000000000003044092320002333");

  formData.append('file', audioBlob, 'audio.wav');
  formData.append('providedText', providedText);

  const config = {
    method: 'post',
    url: url,
    headers: { "Content-Type": "multipart/form-data" },
    data : formData,
  };

  return axios(config).then(function (response) {

    if( response?.data?.success ) {

      const responseData = response.data;
      responseData.audioFileUri = audioFileUri;

      return responseData;

    } else {
      let errorMessage = 'Sorry, there was an error. Please try again';
      if( response?.data?.error ) { errorMessage = response?.data?.error }

      return { success: false, error: errorMessage}
    }
  }).catch(function (error) {
    reportError(error);
    return { success: false, error: error}
  });

}

export default scorePronunciation;




// --

function generateDummyData(audioFileUri, providedText) {

  const pronunciationPercent = generateRandomScore();
  const pronunciationWord = setScoreWord(pronunciationPercent)
  const wordScore = [];

  providedText.split(' ').map((word, index) => {
    const number = Math.floor(Math.random() * 90) + 20
    wordScore.push(
      {
        qualityScore: number,
        qualityScoreClass: setScoreWord(number),
        word: word,
        phone_score_list: [
          {
            phone: 't',
            quality_score: 70,
            sound_most_like: 'n',
          },
          {
            phone: 'eh',
            quality_score: 100,
            sound_most_like: 'eh',
          },
          {
            phone: 's',
            quality_score: 98.53,
            sound_most_like: 's',
          },
          {
            phone: 't',
            quality_score: 40
          }
        ]
      },
    )
  });


  const dummyData = {
    success: true,
    transcribed: true,
    ieltsScores: {
      pronunciation: generateRandomIeltsScore()
    },
    overall: {
      percent: pronunciationPercent,
      word: pronunciationWord
    },
    pronunciation: {
      percent: pronunciationPercent,
      word: pronunciationWord
    },
    wordScore: wordScore,
    audioFileUri: audioFileUri
  }

  return dummyData;
}

function generateRandomScore() {
  return Math.floor(Math.random() * (100 - 15 + 1) + 15);
}

function generateRandomIeltsScore() {
  return (Math.floor(Math.random() * (9 - 1 + 1) + 1)).toFixed(1);
}

function setScoreWord(percent) {
  let word;

  if( percent >= 80 ) {
    word = 'Excellent';
  } else if( percent <= 79 && percent >= 56 ) {
    word = 'Good';
  } else {
    word = 'Ok';
  }

  return word;
}