import React from 'react';
import { Link } from 'react-router-dom';

import '../../../styles/lessonCard.css';
import generateLessonLinkPath from '../../../functions/generateLessonLinkPath';
import LessonCardAction from './lessonCardAction';
import VectorIcon from "../vectorIcon";

const defaultImageUrl = 'https://images.pexels.com/photos/7130552/pexels-photo-7130552.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2';


function LessonCardPrimary(props) {

  // Define variables
  const lesson = props.lesson;
  const isRepeat = lesson.isRepeat;
  const hasAccessToLesson = lesson.learnerHasAccessToLesson;
  const lessonType = lesson.lessonType;
  const level = lesson.level;
  const thumbnailUrl = lesson.thumbnailUrl || defaultImageUrl;
  const exercise = lesson.exercise || {};
  const complete = exercise.isComplete;
  const exerciseId = exercise.id;
  const score = exercise.score;
  const isIelts = props.isIelts;
  const ieltsScore = exercise.scoreIelts;
  const showCompletedDate = props.showCompletedDate;
  const linkPath = generateLessonLinkPath(lessonType, lesson.id);
  let displayLessonType = lesson.displayLessonType;


  // Screen components
  let icon;
  if( complete ) {
    if( hasAccessToLesson ) {
      icon = <VectorIcon icon='checkMarkCircle' size={'1.15rem'} color='#BC0181' />;
    } else {
      icon = <VectorIcon icon='lock' size={'1.15rem'} color='#BC0181' />;
    }
  } else {
    if( hasAccessToLesson ) {
    } else {
      icon = <VectorIcon icon='lock' size={'1.15rem'} color='#8F8F8F' />;
    }
  }


  let repeatElement;
  if( isRepeat ) {
    repeatElement = (
      <button
        class='clean-button repeat-element-container'
        type='button'
        onClick={() => alert("Repetition is key to improvement. Do this lessons again to lock it in your memory!")}
      >
        <p class='repeat-lesson'>Repeat lesson</p>
        <span class='icon icon-info' />
      </button>
    );
  }

  let completedOnElement;
  if( showCompletedDate && lesson.completedOn ) {
    completedOnElement = <p class='completed_on'>Completed on {lesson.completedOn}</p>;
  }


  return (
    <div class='lesson-card-wrapper'>

      <div class='lesson-card-details-container'>

        <div
          class='lesson-card-image'
          style={{backgroundImage: `url(${thumbnailUrl})`}}
        ></div>

        <div class='flex-column lesson-card-content-container'>

          <Link to={linkPath} class='lesson-card-content' title='Start lesson'>

            <div class='flex-row lesson-card-type-container'>
              <p class='display-lesson-type'>{displayLessonType}</p>
              {icon}
            </div>

            <h6>{lesson.title}</h6>
            <p class='small'>
              <span class='card-detail-level'><i>{level}{lesson.duration ? ',' : null}</i></span>
              &nbsp;
              <span class='card-detail-duration'><i>{lesson.duration}</i></span>
            </p>
            {repeatElement}
            {completedOnElement}
          </Link>

          <LessonCardAction
            complete={complete}
            score={score}
            exerciseId={exerciseId}
            isIelts={isIelts}
            ieltsScore={ieltsScore}
            // hasAccessToLesson={hasAccessToLesson}
            linkPath={linkPath}
          />

        </div>

      </div>

    </div>
  );
}

export default LessonCardPrimary;
