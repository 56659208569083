import { useState, useEffect } from "react";


function ReviewTimer(props) {

  // Define variables
  const [counter, setCounter] = useState(props.seconds);
  const nextAction = props.nextAction;


  // Component load actions
  useEffect(() => {

    if( counter === 0 ) {
      nextAction();
    }

    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);


  return (
    <div class='asset-review-timer-text'>
      <p>Screen will automatically continue in {counter} seconds</p>
    </div>
  );
}

export default ReviewTimer;