function SaveModalBody() {
  return (
    <div class='flex-column'>
      <br />
      <p class='assess-fancy'>Saving your data</p>
      <div class='loading-general' style={{marginTop: '45px'}}>
        <div class='button is-loading'></div>
        <p>Saving ...</p>
      </div>
    </div>
  );
}

export default SaveModalBody;