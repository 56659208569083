import { useState } from "react";

import { assessmentEmailReport } from "../../utilities/auth/authApi";
import apiCall from "../../utilities/auth/apiCall";
import alertMessage from "../../utilities/alertMessage";
import { reportError } from "../../../functions/sentry";


function ScoreReportEmail(props) {

  // Define variables
  const [sendStatus, setSendStatus] = useState('form');
  const [email, setEmail] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState('');
  const code = props.code;


  // Define functions
  function handleSubmit(event) {
    event.preventDefault();
    setButtonDisabled(true);

    // Simple validation
    if( email.length < 6 ) {
      alertMessage('Please enter a valid email address', 'error');
      setTimeout(() => {
        setButtonDisabled(false);
      }, 2000);
      return;
    }

    const payload = {
      code: code,
      email: email
    }

    apiCall(assessmentEmailReport, payload).then((response) => {
      if( response.success ) {
        setSendStatus('success');
      } else {
        alertMessage(response.error, 'error');
        reportError(response.error)
      }
    }).finally(() => {
      setTimeout(() => setButtonDisabled(false), 2000);
    });
  }


  // Screen components
  let bodyContent;
  if( sendStatus === 'form' ) {
    bodyContent = (
      <form
        onSubmit={(event) => handleSubmit(event)}
        class='flex-column'
        style={{width: '100%'}}
      >
        <p>Email my report</p>
        <div style={{width: '100%', maxWidth: '300px'}}>
          <input
            value={email}
            onChange={event => setEmail(event.target.value)}
            class='input'
            placeholder="Your email"
            type='email'
            minLength={6}
            required
            style={{textAlign: 'center'}}
          />
        </div>
        <button
          type='submit'
          class={`button ${buttonDisabled ? 'is-loading' : ''}`}
          disabled={buttonDisabled}
          style={{minWidth: '150px', marginTop: '15px'}}
        >Send</button>
      </form>
    );
  } else if ( sendStatus === 'success' ) {
    bodyContent = (
      <div class='flex-column'>
        <p><strong>Success</strong></p>
        <p class='has-text-centered'>Your report is being generated and will be emailed to you within an hour.</p>
      </div>
    );
  }


  return (
    <>
      {bodyContent}
    </>
  );
}

export default ScoreReportEmail;