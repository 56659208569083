import { useState } from "react";

import alertMessage from "../utilities/alertMessage";
import CatalogList from "./catalogList";

const initialCode = ( process.env.NODE_ENV === 'development' ) ? '09800' : '';

function CatalogScreen() {

  // Define variables
  const [canViewCatalog, setCanViewCatalog] = useState(false);
  const [code, setCode] = useState(initialCode);
  const [buttonDisabled, setButtonDisabled] = useState(false);


  // Define functions
  function handleSubmit(event) {
    event.preventDefault();

    setButtonDisabled(true);
    let error = 'Sorry, your code is not valid. Please contact support@flowspeak.io for assistance.'

    if( code.length === 6 ) {
      if( code.includes('098') ) {
        error = null;
        setCanViewCatalog(true);
      }
    }

    if( error ) {
      alertMessage(error, 'error');
    }

    setTimeout(() => {
      setButtonDisabled(false);
    }, 1000);
  }


  // Screen components
  let bodyElement;
  if( canViewCatalog ) {
    bodyElement = <CatalogList />;
  } else {
    bodyElement = (
      <div style={{marginTop: '45px', maxWidth: '400px'}}>
        <h3>Enter your code to view the catalog</h3>
        <form onSubmit={(event) => handleSubmit(event)}>
          <input
            value={code}
            onChange={event => setCode(event.target.value)}
            class='input'
          />
          <br />
          <br />
          <button
            type='submit'
            class='button'
            disabled={buttonDisabled}
          >
            Submit
          </button>
        </form>
      </div>
    )
  }


  return (
    <div class='wrapper'>
      <div class='main-body' style={{paddingTop: '60px', paddingBottom: '120px'}}>
        <div class='main-body-content catalog'>

          <div class='unauthed-start-header-logo'></div>

          <div style={{marginTop: '45px'}}>
            <h1 style={{marginBottom: '45px'}}>Lesson Catalog</h1>
            {/* <p>Bites, Listening, Expressions, Boosters, Conversations, & AI Talk lessons</p> */}


            <p>
              New Voice Learning Inc
              <br />
              3870 Murphy Canyon Rd., Ste 200
              <br />
              San Diego, CA 92123
              <br />
              <a href='https://flowspeak.io' target='_blank' rel='noreferrer' title='FLOW Speak website'>flowspeak.io</a>
              <br />
              <br />
              <i>Copyright - all rights reserved</i>
            </p>
          </div>

          {bodyElement}

        </div>
      </div>
    </div>
  )
}

export default CatalogScreen;