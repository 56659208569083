import MicrophoneAccessContent from "../../help/microphoneaccesscontent";


function CodeMicDenied() {
  return (
    <>
      <div class='flex-column'>
        <br />
        <p class='assess-h6'>We cannot access your microphone</p>
        <p class='has-text-centered'></p>
        <p class='has-text-centered'>We need access to your microphone so we can record your voice.</p>
        <br />

        <br />
        <br />
        <p class='has-text-centered'>
          <strong>How to allow access to your microphone</strong>
        </p>
        <br />
        <MicrophoneAccessContent />
        <br />
        <p class='has-text-centered'>After you have granted access to your microphone, reload this page.</p>
        <br />
        <button
          type='button'
          class='button'
          onClick={() => window.location.reload()}
        >Reload</button>
      </div>
    </>
  );
}

export default CodeMicDenied;