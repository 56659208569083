import ScoreDonutWithText from "../../utilities/learning_module/scoreDonutWithText";


function AsessScoreDonut(props) {

  // Define variables
  const score = props.score;
  const title = props.title;


  return (
    <div class='flex-column assess-response-scores-row-donut'>
      <ScoreDonutWithText percent={score.score} text={score.label} />
      <p class='assess-score-response-p'>{title}</p>
    </div>
  );
}

export default AsessScoreDonut;