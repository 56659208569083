import { useState } from "react";

import PlayAudioIcon from "../../utilities/playaudioicon";
import ReviewTimer from "./reviewTimer";
import ButtonSubmitRound from "../../utilities/forms/buttonSubmitRound";


function Review(props) {

  // Define variables
  const [loading, setLoading] = useState(false);
  const instruction = props.instruction;
  const audioUrl = props.audioUrl;
  const buttonFunction = props.buttonFunction;
  const seconds = props.seconds;
  const retryCount = props.retryCount;
  const retryPossible = props.retryPossible;
  const retry = props.retry;


  // Define functions
  function handleButtonClick() {
    setLoading(true);
    buttonFunction();
  }


  // Screen components
  let retryButton;
  if( retryCount < retryPossible ) {
    retryButton = (
      <button
        class='button assess-retry-button'
        onClick={() => retry()}
      >I want to retry</button>
    );
  }


  return (
    <div>

      <div class='flex-column assess-review-container'>
        <p class='assess-fancy small'>{instruction}</p>


        <div class='assess-review-audio-container'>
          <PlayAudioIcon audioUrl={audioUrl} colorScheme={'light'} width={'40px'} />
        </div>
        <p><small>Tap to listen</small></p>

        <div style={{marginTop: '45px'}}>
          {retryButton}
        </div>
      </div>

      <div class='flex-column'>
        <ButtonSubmitRound
          value={"Continue"}
          handleButtonClick={() => handleButtonClick()}
          loading={loading}
        />
        <ReviewTimer seconds={seconds} nextAction={() => buttonFunction()} />
      </div>
    </div>
  );
}

export default Review;