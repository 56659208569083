import RenderHtmlText from "../../utilities/renderhtmltext";
import ButtonSubmitRound from "../../utilities/forms/buttonSubmitRound";


function AssessmentInstruction(props) {

  // Define variables
  const instructions = props.instructions;
  const logProgress = props.logProgress;
  const nextStep = props.nextStep;
  const passageStep = props.passageStep;


  // Screen components
  let headingElement;

  if( instructions.heading ) {
    headingElement = (
      <div class='assess-instruction-heading'>
        <RenderHtmlText string={instructions.heading} />
      </div>
    )
  }


  let retryElement = instructions.retry ? <RenderHtmlText string={instructions.retry} /> : null;
  let translationRetryElement = instructions.retryTranslation ? <RenderHtmlText string={instructions.retryTranslation} /> : null;
  let translationElement;

  if( instructions.translation ) {
    const headingTranslationElement = instructions.headingTranslation ? <RenderHtmlText string={instructions.headingTranslation} /> : null;

    translationElement = (
      <div class='assess-translate-container'>
        {headingTranslationElement}
        <RenderHtmlText string={instructions.translation} />
        {translationRetryElement}
      </div>
    );
  }


  return (
    <>
      <div class='flex-column assess-glass'>

        <div class='flex-column' style={{width: '100%'}}>
          <h2>Instructions</h2>

          <div class='assess-instructions-container'>
            {headingElement}
            <RenderHtmlText string={instructions.body} />
            {retryElement}
          </div>

          {translationElement}
        </div>

        <div class='flex-column'>
          <ButtonSubmitRound
            value={"Continue"}
            handleButtonClick={() => logProgress({step: nextStep, passageStep: passageStep})}
          />
        </div>
      </div>
    </>
  );
}

export default AssessmentInstruction;